<template>
  <v-container fluid>
    <v-alert
        dense
        :type="alert.type"
        timeout="5000"
    v-if="alert.message">
        {{ alert.message }}
    </v-alert>
      <v-layout row wrap>
        <template>
          <v-flex
            sm=12
            xs=12
            md=12
            lg=12
          >
            <v-card>
              <v-card-title>
                <v-row>
                  <v-col cols=12 md=6 lg="3" xl="3">
                    <v-btn
                      color="success"
                      dark
                      @click="doManager()"
                      class="mx-2"
                    >
                      New HWID
                    <v-icon
                      dark
                      right
                    >
                      mdi-plus
                    </v-icon>
                    </v-btn>
                  </v-col>
                  <v-col cols=12 md=6 lg="3" xl="3">
                    <v-btn
                      class="mx-2"
                      @click="toggle_exclude = !toggle_exclude"
                      :color="toggle_exclude ? 'green':'blue'"
                    >
                     {{ toggle_exclude == false ? 'Show Exclude':'Show All' }}
                    </v-btn>
                  </v-col>
                  <v-col cols=12 md="6" lg="6" xl="6">
                    <v-text-field
                      v-model="search"
                      append-icon="mdi-magnify"
                      label="Search"
                      single-line
                      hide-details
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-card-title>
              <v-data-table
                :headers="headers"
                :items="Items"
                :search="search"
                item-key="key_login"
                :footer-props="{'items-per-page-options':[15,30,50,100]}"
                :items-per-page="15"
              >
                <template v-slot:[`item.bl_hwid`]="{ item }">
                  <div>
                  <v-btn
                    dark
                    text
                    small
                    v-clipboard="item.bl_hwid"
                    class="mx-2"
                  >
                    <v-icon
                      light
                      left
                    >
                      mdi-cellphone-link
                    </v-icon>
                    {{item.bl_hwid}}
                  </v-btn>
                  </div>
                </template>
                <template v-slot:[`item.bl_exclude`]="{ item}">
                  <v-checkbox
                    v-model="item.bl_exclude"
                    :disabled="on_change !== null"
                    :loading="on_change == item.bl_id"
                    :label="item.bl_exclude ? 'Exclude':'None'"
                    @change="doChange(item)"
                  />
                </template>
                <template v-slot:[`item.bl_reason`]="{ item }">
                  <v-textarea
                    class="mx-2"
                    label="Reason"
                    rows="2"
                    prepend-icon="mdi-comment"
                    :value="item.bl_reason"
                  ></v-textarea>
                </template>
                <template v-slot:[`item.key_id`]="{ item }">
                  <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="deep-orange darken-1"
                        dark
                        v-bind="attrs"
                        v-on="on"
                        small
                      >
                        Manager
                      <v-icon
                        light
                        right
                      >
                        mdi-pencil
                      </v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item>
                        <v-list-item-title>
                          <v-btn value="justify" color="warning" text small @click="doDelete(item.bl_id)">
                            <v-icon
                              light
                              left
                            >
                              mdi-delete
                            </v-icon>
                            Delete
                          </v-btn>
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </template>
            </v-data-table>
            </v-card>
          </v-flex>
        </template>
      </v-layout>
    <v-overlay :value="Overlay">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
        <v-dialog
          v-model="dialog"
          transition="dialog-bottom-transition"
          max-width="600"
          dark
        >
        <validation-observer
          ref="observer"
          v-slot="{ invalid }"
        >
        <v-form ref="form" @submit.prevent="doProcess">
        <v-card>
        <v-toolbar
            color="primary"
            dark
        >BLACKLIST HWID MANAGER</v-toolbar>
        <v-card-text>
            <div class="text-h2 pa-12">
              <validation-provider
                v-slot="{ errors }"
                name="HWID"
                rules="required|min:1"
              >
            <v-text-field v-model="hwidData.bl_hwid" label="HWID" :error-messages="errors">
                <v-icon
                    slot="prepend"
                >
                    mdi-cellphone-link
                </v-icon>
            </v-text-field>
            </validation-provider>
              <v-textarea
                outlined
                label="Reason"
                v-model="hwidData.bl_reason"
              ></v-textarea>
            <v-btn-toggle borderless>
              <v-btn value="left" color="primary" type="submit" :disabled="invalid">
                  <span class="hidden-sm-and-down">Create</span>
                  <v-icon right>
                  mdi-plus
                  </v-icon>
              </v-btn>
            </v-btn-toggle>
            <div v-if="errorSubmit">
              <br />
              <v-alert
                text
                prominent
                type="error"
                icon="mdi-cloud-alert"
                timeout="5000"
              >
                {{ errorSubmit }}
              </v-alert>
            </div>
            </div>
        </v-card-text>
        <v-card-actions class="justify-end">
            <v-btn
            text
            @click="dialog = false"
            >Close</v-btn>
        </v-card-actions>
        </v-card>
        </v-form>
          </validation-observer>
        </v-dialog>
  </v-container>
</template>

<script>
import { fetchData } from '../helpers';
import { mapState, mapActions } from 'vuex'
import { clipboard } from 'vue-clipboards';
import { required, digits, min, max, regex } from 'vee-validate/dist/rules'
import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate'
setInteractionMode('eager')
  extend('digits', {
    ...digits,
    message: '{_field_} needs to be {length} digits. ({_value_})',
  })
  extend('required', {
    ...required,
    message: '{_field_} can not be empty',
  })
  extend('min', {
    ...min,
    message: '{_field_}  must be {length} or more',
  })
  extend('max', {
    ...max,
    message: '{_field_} may not be greater than {length} characters',
  })
  extend('regex', {
    ...regex,
    message: '{_field_} {_value_} does not match {regex}',
  })
export default {
  name: 'Blacklist',
  directives: { clipboard },
  components: {
    ValidationObserver,
    ValidationProvider
  },
  computed: {
      ...mapState('auth', ['status']),
      ...mapState({
          alert: state => state.alert
      }),
      Items(){
        return this.items.filter(item=>(this.toggle_exclude == true) ? item.bl_exclude === true:true);
      }
  },
  methods: {
    ...mapActions('auth', ['login', 'logout']),
    ...mapActions('alert', ['success', 'error']),
    doChange: function(item){
      this.on_change = item.bl_id;
      fetchData(`${this.$API}/api/Blacklist/exclude`,{ id: item.bl_id, status: item.bl_exclude}).then((json)=>{
          let message = (json.msg) ? json.msg:"Server Error !!!";
            if(json.success == true){
                this.success(message);
            }else{
                this.error(message);
            }  
      },(err)=>{
        if(err === 401){
          this.logout();
        }else{
          this.error('SERVER ERROR !!!');
        }
      }).finally(()=>{
        this.fetchData();
      });
      // const index = this.items.indexOf(item);
      // if(index > -1){
      //   this.items[index].bl_exclude = !item.bl_exclude;
      // }
    },
    fetchData: function(request){
      this.Overlay = true;
      fetchData(`${this.$API}/api/Blacklist/get`,request).then((json)=>{
          this.items = json;
      },(err)=>{
        if(err === 401){
          this.logout();
        }else{
          this.error('SERVER ERROR !!!');
        }
      }).finally(()=>{
        this.Overlay = false;
        this.on_change = null;
      });
    },
    doCopyKey: function(item){
      if(item.key_status === 1){
          return item.key_login;
      }
    },
    doManager: function(){
      this.errorSubmit = "";
      this.dialog = true;
      this.hwidData = {};
    },
    doProcess: function(){
      this.Overlay = true;
      fetchData(`${this.$API}/api/Blacklist/add`,this.hwidData).then((json)=>{
          let message = (json.msg) ? json.msg:"Server Error !!!";
            if(json.success == true){
                this.success(message);
                this.fetchData();
            }else{
                this.error(message);
            }  
      },(err)=>{
        if(err === 401){
          this.logout();
        }else{
          this.error('SERVER ERROR !!!');
        }
      }).finally(()=>{
        this.Overlay = false;
        this.dialog = false;
      });
    },
    doDelete: function(bl_id){
      this.Overlay = true;
      this.$confirm(`Do you really want to delete ? This data can't restore !`,{ title: 'Warning' }).then(res => {
        if(res === true){
          fetchData(`${this.$API}/api/Blacklist/delete`,{ bl_id },'DELETE').then((json)=>{
              let message = (json.msg) ? json.msg:"Server Error !!!";
                if(json.success == true){
                    this.success(message);
                    this.fetchData();
                }else{
                    this.error(message);
                }  
          },(err)=>{
            if(err === 401){
              this.logout();
            }else{
              this.error('SERVER ERROR !!!');
            }
          }).finally(()=>{
            this.Overlay = false;
            this.dialog = false;
          });
        }
      });
    },
  },
  data : () => ({
    on_change: null,
    toggle_exclude: false,
    hwidData: {},
    Overlay: false,
    dialog: false,
    search: '',
    errorSubmit: "",
    invalid: true,
    headers: [
            { text: 'Exclude', align: 'center', sortable: false,value: 'bl_exclude',width:"5%"},
            { text: 'HWID', align: 'right', sortable: false,value: 'bl_hwid',width:"10%" },
            { text: 'Reason', align: 'right', sortable: true,value: 'bl_reason',width:"20%"},
            { text: 'Seller', align: 'center', sortable: true,value: 'bl_seller',width:"10%"},
            { text: 'Date/Time', align: 'center', sortable: true,value: 'bl_date',width:"20%"},
            { text: 'Manage', align: 'center', sortable: false,value: 'key_id',width: "10%" },
        ],
    items: [],
  }),
  watch:{

  },
  mounted(){
    this.fetchData();
  }
}
</script>
<style>
</style>