var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[(_vm.alert.message)?_c('v-alert',{attrs:{"dense":"","type":_vm.alert.type,"timeout":"5000"}},[_vm._v(" "+_vm._s(_vm.alert.message)+" ")]):_vm._e(),_c('v-layout',{attrs:{"row":"","wrap":""}},[[_c('v-flex',{attrs:{"sm":"12","xs":"12","md":"12","lg":"12"}},[_c('v-card',[_c('v-card-title',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6","lg":"3","xl":"3"}},[_c('v-btn',{staticClass:"mx-2",attrs:{"color":"success","dark":""},on:{"click":function($event){return _vm.doManager()}}},[_vm._v(" New HWID "),_c('v-icon',{attrs:{"dark":"","right":""}},[_vm._v(" mdi-plus ")])],1)],1),_c('v-col',{attrs:{"cols":"12","md":"6","lg":"3","xl":"3"}},[_c('v-btn',{staticClass:"mx-2",attrs:{"color":_vm.toggle_exclude ? 'green':'blue'},on:{"click":function($event){_vm.toggle_exclude = !_vm.toggle_exclude}}},[_vm._v(" "+_vm._s(_vm.toggle_exclude == false ? 'Show Exclude':'Show All')+" ")])],1),_c('v-col',{attrs:{"cols":"12","md":"6","lg":"6","xl":"6"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.Items,"search":_vm.search,"item-key":"key_login","footer-props":{'items-per-page-options':[15,30,50,100]},"items-per-page":15},scopedSlots:_vm._u([{key:"item.bl_hwid",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('v-btn',{directives:[{name:"clipboard",rawName:"v-clipboard",value:(item.bl_hwid),expression:"item.bl_hwid"}],staticClass:"mx-2",attrs:{"dark":"","text":"","small":""}},[_c('v-icon',{attrs:{"light":"","left":""}},[_vm._v(" mdi-cellphone-link ")]),_vm._v(" "+_vm._s(item.bl_hwid)+" ")],1)],1)]}},{key:"item.bl_exclude",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{attrs:{"disabled":_vm.on_change !== null,"loading":_vm.on_change == item.bl_id,"label":item.bl_exclude ? 'Exclude':'None'},on:{"change":function($event){return _vm.doChange(item)}},model:{value:(item.bl_exclude),callback:function ($$v) {_vm.$set(item, "bl_exclude", $$v)},expression:"item.bl_exclude"}})]}},{key:"item.bl_reason",fn:function(ref){
var item = ref.item;
return [_c('v-textarea',{staticClass:"mx-2",attrs:{"label":"Reason","rows":"2","prepend-icon":"mdi-comment","value":item.bl_reason}})]}},{key:"item.key_id",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"deep-orange darken-1","dark":"","small":""}},'v-btn',attrs,false),on),[_vm._v(" Manager "),_c('v-icon',{attrs:{"light":"","right":""}},[_vm._v(" mdi-pencil ")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',[_c('v-list-item-title',[_c('v-btn',{attrs:{"value":"justify","color":"warning","text":"","small":""},on:{"click":function($event){return _vm.doDelete(item.bl_id)}}},[_c('v-icon',{attrs:{"light":"","left":""}},[_vm._v(" mdi-delete ")]),_vm._v(" Delete ")],1)],1)],1)],1)],1)]}}],null,true)})],1)],1)]],2),_c('v-overlay',{attrs:{"value":_vm.Overlay}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1),_c('v-dialog',{attrs:{"transition":"dialog-bottom-transition","max-width":"600","dark":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.doProcess.apply(null, arguments)}}},[_c('v-card',[_c('v-toolbar',{attrs:{"color":"primary","dark":""}},[_vm._v("BLACKLIST HWID MANAGER")]),_c('v-card-text',[_c('div',{staticClass:"text-h2 pa-12"},[_c('validation-provider',{attrs:{"name":"HWID","rules":"required|min:1"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"HWID","error-messages":errors},model:{value:(_vm.hwidData.bl_hwid),callback:function ($$v) {_vm.$set(_vm.hwidData, "bl_hwid", $$v)},expression:"hwidData.bl_hwid"}},[_c('v-icon',{attrs:{"slot":"prepend"},slot:"prepend"},[_vm._v(" mdi-cellphone-link ")])],1)]}}],null,true)}),_c('v-textarea',{attrs:{"outlined":"","label":"Reason"},model:{value:(_vm.hwidData.bl_reason),callback:function ($$v) {_vm.$set(_vm.hwidData, "bl_reason", $$v)},expression:"hwidData.bl_reason"}}),_c('v-btn-toggle',{attrs:{"borderless":""}},[_c('v-btn',{attrs:{"value":"left","color":"primary","type":"submit","disabled":invalid}},[_c('span',{staticClass:"hidden-sm-and-down"},[_vm._v("Create")]),_c('v-icon',{attrs:{"right":""}},[_vm._v(" mdi-plus ")])],1)],1),(_vm.errorSubmit)?_c('div',[_c('br'),_c('v-alert',{attrs:{"text":"","prominent":"","type":"error","icon":"mdi-cloud-alert","timeout":"5000"}},[_vm._v(" "+_vm._s(_vm.errorSubmit)+" ")])],1):_vm._e()],1)]),_c('v-card-actions',{staticClass:"justify-end"},[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v("Close")])],1)],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }