<template>
    <v-container fluid>
        <v-layout row wrap>
            <v-card>
                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            <v-card>
                                <v-card-title>
                                    <div class="red" v-if="selectedSeller.seller_id == 0">Please pick seller to check log</div>
                                </v-card-title>

                                <v-card-text>
                                    <v-autocomplete
                                        v-model="selectedSeller"
                                        :items="sellers"
                                        label="Seller"
                                        item-text="username"
                                        item-value="seller_id"
                                        hint="Search by Seller"
                                        persistent-hint
                                    />
                                </v-card-text>
                                <v-card-actions>
                                    <v-btn color="cyan"
                                        :disabled="Overlay || selectedSeller.seller_id == 0"
                                        :loading="Overlay"
                                        @click="fetchData(selectedSeller)"
                                    >
                                        Check
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-col>
                        <v-col cols="12">
                            <v-data-table
                                :headers="headers"
                                :items="items"
                                item-key="log_id"
                                :loading="Overlay"
                                loading-text="Loading... Please wait"
                                :footer-props="{'items-per-page-options':[15,30,50,100]}"
                                :items-per-page="20"
                            >
                                <template v-slot:[`item.ip`]="{ item }">
                                    <span class="justify">{{ item.ip }} {{ item.country }} <gb-flag :code="item.country.toString().toLowerCase()" size="mini"/></span>
                                </template>
                                <template v-slot:[`item.action`]="{ item }">
                                    {{ action_details[item.action] }}
                                </template>
                                <template v-slot:[`item.time`]="{ item }">
                                    {{ doParseTime(item.time) }}
                                </template>
                                <template v-slot:[`item.ua`]="{ item }">
                                    <template v-if="get_ua(item.ua).os">
                                        <img :src="get_ua(item.ua).os" />
                                    </template>
                                    <template v-if="get_ua(item.ua).browser">
                                        <img :src="get_ua(item.ua).browser" />
                                    </template>
                                    {{item.ua }}
                                </template>
                            </v-data-table>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-layout>
    </v-container>
  </template>
  
  <script>
  import { fetchData, doParseTime } from '../helpers';
  import { mapState, mapActions } from 'vuex';
  import UAHelper from '../helpers/browsers';
  export default {
    name: "Logs",
    computed:{
        ...mapState('lang', ['lang']),
        ...mapState('auth', ['status']),
        ...mapState('SellerStats', ['SellerStats']),
        ...mapState({
            alert: state => state.alert
        }),
        headers(){
            return [
                { text: "IP", align: 'right', sortable: false,value: 'ip',width:"auto" },
                { text: "User-Agent", align: 'left', sortable: true,value: 'ua',width:"auto"},
                { text: "Seller", align: 'left', sortable: true,value: 'username',width:"auto"},
                { text: "Time", align: 'left', sortable: true,value: 'time',width:"auto" },
                { text: "Action", align: 'left', sortable: false,value: 'action',width:"auto" },
            ]
        },
        action_details(){
            return {
                1: "LOGIN",
                2: "CHARGE",
                3: "DELETE",
            }
        }
    },
    methods:{
        ...mapActions('auth', ['login', 'logout']),
        ...mapActions('alert', ['success', 'error']),
        doParseTime,
        get_ua: function(ua) {
            return UAHelper.get_image(UAHelper.ua(ua));
        },
        fetchData: function(seller_id){
            this.Overlay = true;
            fetchData(`${this.$API}/api/audit-logs`, {seller_id}).then((resp)=>{
                this.items = resp;
            },(err)=>{
                if(err === 401){
                    this.logout();
                }else{
                    this.error('SERVER ERROR !!!');
                }
            }).finally(()=>{
                this.Overlay = false;
            });
        },
        fetchLast50: function(overlay = false){
            if(overlay) this.Overlay = true;
            fetchData(`${this.$API}/api/audit-logs/last`).then((resp)=>{
                this.items = resp;
            },(err)=>{
                if(err === 401){
                    this.logout();
                }else{
                    this.error('SERVER ERROR !!!');
                }
            }).finally(()=>{
                if(overlay) this.Overlay = false;
            });
        },
        fetchSellers: function(overlay=false){
            if(overlay) this.Overlay = true;
            fetchData(`${this.$API}/api/Seller/getList`).then((json)=>{
                this.sellers = this.sellers.concat(json);
                this.sellersLists = json;
            },(err)=>{
                if(err === 401){
                this.logout();
                }else{
                this.error('SERVER ERROR !!!');
                }
            }).finally(()=>{
                if(overlay) this.Overlay = false;
            });
        },
    },
    data : () => ({
        items: [],
        sellers: [{seller_id:0,username:"All"}],
		sellersLists: [],
        selectedSeller: {seller_id:0,username:"All"},
        Overlay: false,

    }),
    mounted(){
        let tasks = [this.fetchLast50(), this.fetchSellers()];
        this.Overlay = true;
        Promise.all(tasks).finally(()=>{
            this.Overlay = false;
        });
    }
  }
  </script>
  
  <style scoped>
  
  </style>