<template>
  <v-container fluid>
    <v-alert
        dense
        :type="alert.type"
        timeout="5000"
    v-if="alert.message">
        {{ alert.message }}
    </v-alert>
    <v-menu
      v-model="addExtraMenu"
      :close-on-content-click="false"
      :nudge-width="200"
      offset-x
      dark
    >
      <v-card>
        <v-card-title>
          <v-row>
            <v-col cols="12">
              <h3>{{ restore.key }}</h3>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-content>
          <v-divider></v-divider>
          <v-list>
            <v-list-item>
              <v-select
                v-model="extraTimet"
                :items="extraTimetList"
                item-value="value"
                item-text="text"
                label="Type"
              />
              <v-text-field v-model="extraTimex" label="Extra Time">
                <v-icon
                    slot="prepend"
                >
                  mdi-clock-outline
                </v-icon>
              </v-text-field>
            </v-list-item>
            <v-list-item>
              <v-text-field v-model="restore.time" label="Extra Time" readonly>
                  <v-icon
                    slot="prepend"
                  >
                    mdi-clock-outline
                  </v-icon>
              </v-text-field>
            </v-list-item>
          </v-list>
        </v-card-content>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            text
            @click="doResetRestoreData()"
            :loading="restore_state"
            :disabled="restore_state"
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="doRestore()"
            :loading="restore_state"
            :disabled="restore_state || restore.time <= 0"
          >
            Restore
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>
    <v-layout row wrap>
    <template>
      <v-flex
        sm=12
        xs=12
        md=12
        lg=12
        xl=12
      >
        <v-card>
          
          <v-card-title>
            <v-row>
              <v-col cols=12 md=6 lg=4 xl=4>
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-title>
            <v-row>
              <v-col cols=12 md=4 lg=2 xl=2>
                <v-select
                  v-model="showFilter.username"
                  :items="sellers"
                  hint="Search By Seller"
                  persistent-hint />
              </v-col>
							<v-col cols=12 md=4 lg=2 xl=2>
								<v-select
									v-model="showFilter.purchaser"
									:items="PurchaserList"
									item-text="username"
									item-value="seller_id"
									hint="Search by Purchaser"
									persistent-hint />
							</v-col>
              <v-col cols=12 md=4 lg=2 xl=2>
                <v-btn
                  :loading="Overlay"
                  :disabled="Overlay"
                  color="blue lighten-3"
                  class="ma-2 white--text"
                  @click="doFilter"
                >
                  Search
                  <v-icon
                    right
                    dark
                  >
                    mdi-crosshairs
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="Items"
            :search="search"
            item-key="key_login"
            :loading="Overlay"
            loading-text="Loading... Please wait"
            :footer-props="{'items-per-page-options':[15,30,50,100]}"
            :items-per-page="15"
          >
            <template v-slot:[`item.key_details`]="{ item }">
              <v-row>
                  <v-col cols=12>
										<br />
                    <v-btn
                      color="blue"
                      small
                      @click="doGetData(item.key_id)"
                    >
                    <v-icon
                      light
                      left
                    >
                      mdi-check
                    </v-icon>
                      GET DATA
                    </v-btn>
                  </v-col>
                  <v-col cols=12>
                    <v-btn
                      white--text
                      text
                      small
                    >
                    <v-icon
                      light
                      left
                    >
                      mdi-check
                    </v-icon>
                      Created At: {{ doParseTime(item.key_createdat) }}
                    </v-btn> 
                  </v-col>
              <template v-if="item.key_details.length === 0">
                <v-col cols=12>
                  <v-btn
                  color="success"
                  text
                  small
                  >
                  <v-icon
                    light
                    left
                  >
                    mdi-check
                  </v-icon>
                    KEY UNUSED
                  </v-btn>
                </v-col>
              </template>
              <template v-else>
                <v-col cols=12>
                  <v-btn
                  color="deep-orange darken-1"
                  text
                  small
                  >
                  <v-icon
                    light
                    left
                  >
                    mdi-clock
                  </v-icon>
                    Key Expire: {{ doParseTime(item.key_details[0])}}
                  </v-btn>
                </v-col>
                <v-col cols=12>
                  <v-btn
                  color="cyan darken-1"
                  text
                  small
                  >
                  <v-icon
                    light
                    left
                  >
                    mdi-clock
                  </v-icon>
                    First Login: {{ doParseTime(item.key_details[1])}}
                  </v-btn>
                </v-col>
                <v-col cols=12>
                  <v-btn
                  color="blue darken-1"
                  text
                  small
                  >
                  <v-icon
                    light
                    left
                  >
                    mdi-clock
                  </v-icon>
                    Last Login: {{ doParseTime(item.key_details[2])}}
                  </v-btn>
                </v-col>
              </template>
              </v-row>
            </template>
            <template v-slot:[`item.discord_id`]="{ item }">
              <template v-if="item.discord_id.length > 0">
                <div style="max-height: 220px; overflow-y: auto;">
                  <v-chip v-for="discord in item.discord_id" :key="discord" small class="mb-1" v-clipboard="discord">
                    {{discord}}
                  </v-chip>
                </div>
              </template>
              <v-btn
                  color="white"
                  text
                  small
                  v-if="item['discord_id'].length === 0"
              >
                NO DATA
              </v-btn>
            </template>
            <template v-slot:[`item.key_login`]="{ item }">
                <template v-if="item.key_expired === false">
                    <v-btn
                      text
                      color="success"
                      medium
                      v-clipboard="item.key_login"
                      v-if="item.key_status === 1"
                      >
                      <v-icon left>mdi-check</v-icon>
                        {{item.key_login}}
                      <v-icon right>mdi-content-copy</v-icon>
                    </v-btn>
                    <v-btn
                        text
                        color="grey"
                        medium
                        v-if="item.key_status !== 1"
                        v-clipboard="item.key_login"
                    >
                      <v-icon left color="error">fas fa-ban</v-icon>
                      <strike>{{item.key_login}}</strike>
                      <v-icon right color="error">mdi-lock</v-icon>
                    </v-btn>
                </template>
                <template v-else>
                    <v-btn
                        text
                        :color="item.freeze > 0 ? 'orange':'red'"
                        medium
                        v-clipboard="item.key_login"
                    >
                      <strike v-if="item.freeze === 0">{{item.key_login}}</strike>
											<template v-else>{{item.key_login}}</template>
                      <v-icon right v-if="item.freeze === 0">mdi-update</v-icon>
											<v-icon right v-else>mdi-lock-clock</v-icon>
                    </v-btn>
                </template>
								<v-row>
                  <v-col cols=auto>
                    <v-btn
                      color="green"
                      @click="doRestoreKey(item)"
                    >
                      Restore Key
                    </v-btn>
                  </v-col>
                </v-row>

            </template>
            <template v-slot:[`item.key_hwid`]="{ item }">
                <v-select
									v-model="item.key_hwid"
									:items="item.key_hwid"
									label="List HWID"
									multiple
									v-if="item.key_hwid.length > 0"
								/>
                <v-btn
                  color="green"
                  text
                  x-small
                  v-if="item.key_hwid.length === 0"
                >
                <v-icon
                  light
                  left
                >
                  mdi-check
                </v-icon>
                  NO HWID
                </v-btn>
            </template>
            <template v-slot:[`item.key_package`]="{ item }">
                <v-btn
                    text
                    :color="PackageColors[item.key_package]"
                    medium
                    >
                    {{item.key_package}}
                    <v-icon right v-if="item.key_multihwid">mdi-monitor-multiple</v-icon>
                    <v-icon right v-else>mdi-monitor</v-icon>
                </v-btn>
            </template>
            <template v-slot:[`item.key_seller`]="{ item }">
              <v-btn
                text
                dark
                small
              >
              {{ item.key_seller }}
              </v-btn>
            </template>
						<template v-slot:[`item.freeze`]="{ item }">
							{{convertHMS(item.freeze)}}
						</template>
					</v-data-table>
        </v-card>
      </v-flex>
    </template>
    </v-layout>
    <v-overlay :value="Overlay">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
		<v-dialog
			v-model="BoxNewRegen"
			max-width="600px"
		>
			<v-card flat>
				<v-card-title>KEY DATA</v-card-title>
				<v-card-text>
					<v-textarea
						v-model="NewKeyData"
						label="New Key"
						readonly
					/>
				</v-card-text>
				<v-card-actions>
					<v-btn
						@click="BoxNewRegen = false"
						dark
					>
						Close
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
    <v-dialog
      v-model="boxData"
      max-width="900px"
    >
      <v-card flat>
        <v-card-title>DATA</v-card-title>
        <v-card-text>
          <v-textarea
            v-model="KeyData"
            dark
          />
        </v-card-text>
        <v-card-actions>
          <v-btn
            @click="boxData = false"
            dark
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-overlay :value="loaderData">
        <v-progress-circular
          indeterminate
          size="64"
        ></v-progress-circular>
      </v-overlay>
    </v-dialog>
  </v-container>
</template>

<script>
import { fetchData } from '../helpers';
import { mapState, mapActions } from 'vuex'
import { clipboard } from 'vue-clipboards';
export default {
  name: 'DeletedKey',
  directives: { clipboard },
  components: {
  },
  computed: {
      ...mapState('auth', ['status']),
			...mapState({
					alert: state => state.alert
			}),
			PurchaserList(){
				let list = [{seller_id: null, username: 'All'}];
				const itemList = this.items.filter(filter=>filter['purchased_id'] > 0);
				const dataList = itemList.map(item=>{
						const index = this.seller_by_id.findIndex(find=>find.seller_id.toString() === item['purchased_id'].toString());
						return {
							seller_id:item['purchased_id'],
							username: (index > -1) ? this.seller_by_id[index]['username']:undefined,
						}
					}
				);
				return list.concat(dataList.filter(find=>find.username !== undefined));
			},
			Items(){
				return this.items.map(item=>{
					let index = -1;
					if(item['purchased_id'] > 0){
						index = this.seller_by_id.findIndex(find=>find.seller_id.toString() == item['purchased_id'].toString());
					}
					return {
						...item,
						key_seller: (index > -1) ? this.seller_by_id[index].username : item.key_seller,
					}
				}
				);
			}
  },
  methods: {
    ...mapActions('auth', ['login', 'logout']),
    ...mapActions('alert', ['success', 'error']),
		convertHMS(value) {
			const sec = parseInt(value, 10); // convert value to number if it's string
			let hours   = Math.floor(sec / 3600); // get hours
			let minutes = Math.floor((sec - (hours * 3600)) / 60); // get minutes
			let seconds = sec - (hours * 3600) - (minutes * 60); //  get seconds
			// add 0 if value < 10; Example: 2 => 02
			if (hours   < 10) {hours   = "0"+hours;}
			if (minutes < 10) {minutes = "0"+minutes;}
			if (seconds < 10) {seconds = "0"+seconds;}
			return hours+':'+minutes+':'+seconds; // Return is HH : MM : SS
		},
    filterData(){
      const filter = Object.assign({},this.showFilter);
      const cleanFilter = this.clean(filter);
      const {username, status, key_package, created, hwid, first_login, purchaser, playedTime} = cleanFilter;
      return {username, status, key_package, created, hwid, first_login, purchaser, playedTime};
    },
    fetchData: function(request){
      this.Overlay = true;
      fetchData(`${this.$API}/api/DKey/get`,request).then((json)=>{
        this.items = json;
      },(err)=>{
        if(err === 401){
          this.logout();
        }else{
          this.error('SERVER ERROR !!!');
        }
      }).finally(()=>{
        //this.Overlay = false;
      });
    },
    clean(obj) {
      for (var propName in obj) {
        if (obj[propName] === null || obj[propName] === undefined || obj[propName] === "All") {
          delete obj[propName];
        }
      }
      return obj
    },
    fetchSellers: function(is_name = true){
			const uri = (is_name) ? 'getName':'get';
      this.Overlay = true;
      fetchData(`${this.$API}/api/Seller/${uri}`).then((json)=>{
        if(is_name) {
					this.sellers = this.sellers.concat(json);
				}else{
					this.seller_by_id = json;
				}
      },(err)=>{
        if(err === 401){
          this.logout();
        }else{
          this.error('SERVER ERROR !!!');
        }
      }).finally(()=>{
        this.Overlay = false;
      });
    },
    doSearchData(){
      const Data = this.searchOnData;
      if(Data){
        this.fetchData({Data});
      }else{
        const filted = this.filterData();
        this.fetchData(filted);
      }
    },
    doCopyKey: function(item){
      if(item.key_status === 1){
          return item.key_login;
      }
    },
    doParseTime: function(data){
      var dt = new Date(data*1000);
      return dt.toLocaleString();
    },
    doFilter: function(){
      this.fetchData(this.filterData());
    },
    doGetData(key_id){
      this.KeyData = '';
      this.loaderData = true;
      this.boxData = true;
      fetchData(`${this.$API}/api/DKey/get-data`,{
        key_id:key_id
      }).then((json)=>{
          let message = (json.msg) ? json.msg:"Server Error !!!";
              if(json.success == true){
                this.KeyData = json.data;
              }else{
                this.boxData = false;
                this.error(message);
              }
        },(err)=>{
            if(err === 401){
              this.logout();
            }else{
              this.boxData = false;
              this.error('SERVER ERROR !!!');
            }
          }).finally(()=>{
          this.loaderData = false;
        });
    },
    doCountExtraTime(){
      const t = this.extraTimet; const tt = this.extraTimex;
      if(tt > 0){
        let time = 0;
        switch(t){
          case 0:
              time = tt*60*60*24;
            break;
          case 1:
              time = tt*60*60;
            break;
          case 2:
            time = tt*60;
            break;
        }
        this.restore.time = time;
      }
    },
    doResetRestoreData(){
      this.addExtraMenu = false;
      this.restore = {
        key: null,
        key_id: null,
        time: 0,
      };
    },
    doRestoreKey(key_data){
      this.restore = {
        key: key_data.key_login,
        key_id: key_data.key_id,
        time: 0,
      };
      this.addExtraMenu = true;
    },
    doRestore(){
      this.restore_state = true;
      const {key_id, time} = this.restore;
      fetchData(`${this.$API}/api/DKey/restore`,{key_id, time}).then((json)=>{
        this.items = json;
        if(json.success){
          this.success("Key has been restored !!!");
          this.doResetRestoreData();
          this.fetchData();
        }else{
          const msg = json.msg || "There was a problem restoring your key !!!";
          this.error(msg);
        }
      },(err)=>{
        if(err === 401){
          this.logout();
        }else{
          this.error('SERVER ERROR !!!');
        }
      }).finally(()=>{
        this.restore_state = false;
      });
    }
  },
  data : () => ({
    restore_state: false,
    restore:{
      key: null,
      key_id: null,
      time: 0,
    },
		BoxNewRegen: false,
		NewKeyData: null,
    extraTimetList:[{value:0,text:'Days'},{value:1,text:'Hours'},{value:2,text:'Minutes'}],
    extraTimet:0,
    extraTimex:0,
    searchOnData:'',
    showFilter: {
      username: "All",
      status: null,
      key_package: "All",
      created: null,
      hwid: null,
			first_login: null,
			purchaser: null,
			playedTime: null,
    },
    extraTimeAll:true,
    addExtraMenu: false,
    extraTimeKey: "",
    extraTime: 0,
    extraSeller:'All',
    extraTimeExpired: false,
    modifyMode: false,
    Overlay: false,
    dialog: false,
    search: '',
    headers: [
            { text: 'Key', align: 'right', sortable: false,value: 'key_login',width:"auto" },
            { text: 'Discord', align: 'center', sortable: true,value: 'discord_id',width:"auto" },
            { text: 'Detail', align: 'left', sortable: true,value: 'key_details',width:"auto"},
            { text: 'Played Time', align: 'center', sortable: true,value: 'playedTime',width:"auto" },
						{ text: 'Freeze Time', align: 'center', sortable: true,value: 'freeze',width:"auto" },
            { text: 'HWID', align: 'left', sortable: true,value: 'key_hwid',width:"auto" },
            { text: 'Package', align: 'center', sortable: false,value: 'key_package',width:"auto" },
            { text: 'Seller', align: 'left', sortable: false,value: 'key_seller',width: "auto"},
        ],
    items: [],
    sellers: ["All"],
    updateMode: false,
    packages:['All','2HOUR','1DAY','7DAY','15DAY','30DAY'],
    topupSellerData: {},
    topupMethod: "",
    FilterUsed: [{title:"All",search:null},{title:"Unused",search:1},{title:"Used",search:2},{title:"Expired",search:3},{title:"Locked",search:4},{title:"Blacklisted",search:5},{title:"Regenerated",search:6}],
    FilterHWID: [{title:"All",search:null},{title:"Single",search:false},{title:"Multi",search:true}],
    FilterCreated: [{title:"All",search:null},{title:"1 Day",search:86400},{title:"1 Week",search:604800}],
    PackageColors: {"1DAY":"cyan lighten-3","7DAY":"cyan lighten-2","15DAY":"cyan darken-2","30DAY":"cyan darken-4","2HOUR":"green darken-1"},
    KeyData: '',
    loaderData: false,
    boxData: false,
    counter:undefined,
		new_seller: {},
		seller_by_id: [],
		boxSeller: false,
		loaderSeller: false,
  }),
  watch:{
    items: function(){
      this.Overlay = false;
    }
  },
  beforeDestroy(){
    if(this.counter) clearInterval(this.counter);
    this.counter = undefined;
  },
  mounted(){
    this.fetchData();
    this.fetchSellers();
		this.fetchSellers(false);
    this.counter = setInterval(this.doCountExtraTime,1000);
  }
}
</script>
<style>
</style>