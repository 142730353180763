<template>
  <v-container fluid>
    <v-alert
        dense
        :type="alert.type"
        timeout="5000"
    v-if="alert.message">
        {{ alert.message }}
    </v-alert>
      <v-layout row wrap>
        <template>
          <v-flex
            sm=12
            xs=12
            md=12
            lg=12
          >
            <v-card>
              <v-card-title>
                  <v-row>
                    <v-col cols=12 md="3" lg="2" xl="2">
                      <v-menu offset-y>
                          <template v-slot:activator="{ on, attrs }">
                          <v-btn
                              large
                              color="deep-orange darken-1"
                              dark
                              v-bind="attrs"
                              v-on="on"
                          >
                              Manager
                          </v-btn>
                          </template>
                          <v-list>
                          <v-subheader>Manage cheat values</v-subheader>
                            <v-list-item>
                              <v-list-item-title>
                                <v-btn
                                  color="success"
                                  dark
                                  @click="doManager()"
                                >
                                  New Cheat Value
                                  <v-icon
                                    dark
                                    right
                                  >
                                    mdi-plus
                                  </v-icon>
                                </v-btn>
                              </v-list-item-title>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-title>
                                  <v-btn
                                    color="error"
                                    dark
                                    @click="doDelete(true)"
                                  >
                                    Pure All
                                    <v-icon
                                      dark
                                      right
                                    >
                                      mdi-close-octagon
                                    </v-icon>
                                  </v-btn>
                                </v-list-item-title>
                            </v-list-item>
                          </v-list>
                      </v-menu>
                    </v-col>
                    <v-col cols=12 md="3" lg="2" xl="2">
                      <v-text-field label="Secret" v-model=secret @change=doChangeSecret />
                    </v-col>
                    <v-col cols=12 md="3" lg="2" xl="2">
                      <v-text-field label="Type" v-model=typeCheat @change=doChangeType />
                    </v-col>
                    <v-col cols=12 md=3 lg=2 xl=2>
                      <v-file-input
                        accept="text/plain"
                        label="Import Cheat Value"
                        @change="selectFile"
                      />
                    </v-col>
                    <v-col cols=12 md="6" lg="4" xl="4">
                      <v-text-field
                        v-model="search"
                        prepend-icon="mdi-magnify"
                        label="Search"
                        single-line
                        hide-details
                      ></v-text-field>
                    </v-col>
                  </v-row>
              </v-card-title>   
                    <v-data-table
                      :headers="headers"
                      :items="items"
                      :search="search"
                      :footer-props="{'items-per-page-options':[15,30,50,100]}"
                      :items-per-page="15"
                    >
                      <template v-slot:[`item.Lib`]="{ item }">
                        <v-btn
                          white--text
                          text
                          small
                        >
                        {{item.Lib}}
                        </v-btn> 
                      </template>
                      <template v-slot:[`item.Address`]="{ item }">
                        <v-btn
                          white--text
                          text
                          small
                          v-clipboard="item.Address"
                        >
                        {{item.Address}}
                        </v-btn> 
                      </template>
                      <template v-slot:[`item.Bytes`]="{ item }">
                        <v-textarea
                          class="mx-2"
                          label="Bytes"
                          rows="2"
                          :value="item.Bytes"
                        ></v-textarea>
                      </template>
                      <template v-slot:[`item.Id`]="{ item }">
                        <v-menu offset-y>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              color="deep-orange darken-1"
                              dark
                              v-bind="attrs"
                              v-on="on"
                              small
                            >
                              Value Manager
                            <v-icon
                              light
                              right
                            >
                              mdi-pencil
                            </v-icon>
                            </v-btn>
                          </template>
                          <v-list>
                            <v-list-item>
                              <v-list-item-title>
                                <v-btn value="justify" color="warning" text small @click="doDelete(item.Id)">
                                  <v-icon
                                    light
                                    left
                                  >
                                    mdi-delete
                                  </v-icon>
                                  Delete
                                </v-btn>
                              </v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </template>
                  </v-data-table>           
            </v-card>
          </v-flex>
        </template>
    <v-overlay :value="Overlay">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
        <v-dialog
          v-model="dialog"
          transition="dialog-bottom-transition"
          max-width="600"
          dark
        >
        <validation-observer
          ref="observer"
          v-slot="{ invalid }"
        >
        <v-form ref="form" @submit.prevent="doProcess">
        <v-card>
        <v-toolbar
            color="primary"
            dark
        >CHEAT VALUE MANAGER</v-toolbar>
        <v-card-text>
            <div class="text-h2 pa-12">
              <validation-provider
                v-slot="{ errors }"
                name="Lib"
                rules="required|min:1"
              >
                <v-text-field v-model="cheatData.Lib" label="Lib" :error-messages="errors">
                    <v-icon
                        slot="prepend"
                    >
                        mdi-library
                    </v-icon>
                </v-text-field>
            </validation-provider>
            <validation-provider
                v-slot="{ errors }"
                name="Address"
                rules="required|min:1"
              >
                <v-text-field v-model="cheatData.Address" label="Address" :error-messages="errors">
                    <v-icon
                        slot="prepend"
                    >
                        mdi-library
                    </v-icon>
                </v-text-field>
            </validation-provider>
            <validation-provider
                v-slot="{ errors }"
                name="Type"
                rules="required|min:1"
              >
                <v-text-field v-model="cheatData.Type" label="Type" :error-messages="errors">
                    <v-icon
                        slot="prepend"
                    >
                        mdi-library
                    </v-icon>
                </v-text-field>
            </validation-provider>
            <validation-provider
                v-slot="{ errors }"
                name="Bytes"
                rules="required|min:1"
              >
              <v-textarea
                outlined
                label="Bytes"
                v-model="cheatData.Bytes"
                 :error-messages="errors"
              ></v-textarea>
              </validation-provider>
            <v-btn-toggle borderless>
              <v-btn value="left" color="primary" type="submit" :disabled="invalid">
                  <span class="hidden-sm-and-down">Create</span>
                  <v-icon right>
                  mdi-plus
                  </v-icon>
              </v-btn>
            </v-btn-toggle>
            <div v-if="errorSubmit">
              <br />
              <v-alert
                text
                prominent
                type="error"
                icon="mdi-cloud-alert"
                timeout="5000"
              >
                {{ errorSubmit }}
              </v-alert>
            </div>
            </div>
        </v-card-text>
        <v-card-actions class="justify-end">
            <v-btn
            text
            @click="dialog = false"
            >Close</v-btn>
        </v-card-actions>
        </v-card>
        </v-form>
          </validation-observer>
        </v-dialog>
      </v-layout>
  </v-container>
</template>

<script>
import { fetchData } from '../helpers';
import { mapState, mapActions } from 'vuex'
import { clipboard } from 'vue-clipboards';
import { required, digits, min, max, regex } from 'vee-validate/dist/rules'
import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate'
setInteractionMode('eager')
  extend('digits', {
    ...digits,
    message: '{_field_} needs to be {length} digits. ({_value_})',
  })
  extend('required', {
    ...required,
    message: '{_field_} can not be empty',
  })
  extend('min', {
    ...min,
    message: '{_field_}  must be {length} or more',
  })
  extend('max', {
    ...max,
    message: '{_field_} may not be greater than {length} characters',
  })
  extend('regex', {
    ...regex,
    message: '{_field_} {_value_} does not match {regex}',
  })
export default {
  name: 'Blacklist',
  directives: { clipboard },
  components: {
    ValidationObserver,
    ValidationProvider
  },
  computed: {
      ...mapState('auth', ['status']),
        ...mapState({
            alert: state => state.alert
        }),
  },
  methods: {
    ...mapActions('auth', ['login', 'logout']),
    ...mapActions('alert', ['success', 'error']),
    selectFile: function(event){
      var reader = new FileReader();
      reader.readAsText(event);
      reader.onload = () => {
        if(reader.result !== undefined){
          if(reader.result.length > 0){
            this.Overlay = true;
            fetchData(`${this.$API}/api/Cheat/add`,{import:reader.result}).then((json)=>{
                let message = (json.msg) ? json.msg:"Server Error !!!";
                  if(json.success == true){
                      this.success(message);
                      this.fetchData();
                  }else{
                      this.error(message);
                  }  
            },(err)=>{
              if(err === 401){
                this.logout();
              }else{
                this.error('SERVER ERROR !!!');
              }
            }).finally(()=>{
              this.Overlay = false;
              this.dialog = false;
            });
          }
        }
      }
    },
    fetchData: function(request){
      this.Overlay = true;
      fetchData(`${this.$API}/api/Cheat/get`,request).then((json)=>{
          this.items = json;
      },(err)=>{
        if(err === 401){
          this.logout();
        }else{
          this.error('SERVER ERROR !!!');
        }
      }).finally(()=>{
        this.Overlay = false;
      });
    },
    fetchSecret: function(request){
      this.Overlay = true;
      fetchData(`${this.$API}/api/Cheat/secret`,request).then((json)=>{
          this.secret = json.secret;
      },(err)=>{
        if(err === 401){
          this.logout();
        }else{
          this.error('SERVER ERROR !!!');
        }
      }).finally(()=>{
        this.Overlay = false;
      });
    },
    doManager: function(){
      this.errorSubmit = "";
      this.dialog = true;
      this.cheatData = {
        "Type":this.typeCheat
      };
    },
    doProcess: function(){
      this.Overlay = true;
      fetchData(`${this.$API}/api/Cheat/add`,this.cheatData).then((json)=>{
          let message = (json.msg) ? json.msg:"Server Error !!!";
            if(json.success == true){
                this.success(message);
                this.fetchData();
            }else{
                this.error(message);
            }  
      },(err)=>{
        if(err === 401){
          this.logout();
        }else{
          this.error('SERVER ERROR !!!');
        }
      }).finally(()=>{
        this.Overlay = false;
        this.dialog = false;
      });
    },
    doDelete: function(Id){
      this.Overlay = true;
      this.$confirm(`Do you really want to delete ? This data can't restore !`,{ title: 'Warning' }).then(res => {
        if(res === true){
          fetchData(`${this.$API}/api/Cheat/delete`,{ Id },'DELETE').then((json)=>{
              let message = (json.msg) ? json.msg:"Server Error !!!";
                if(json.success == true){
                    this.success(message);
                    this.fetchData();
                }else{
                    this.error(message);
                }  
          },(err)=>{
            if(err === 401){
              this.logout();
            }else{
              this.error('SERVER ERROR !!!');
            }
          }).finally(()=>{
            this.Overlay = false;
            this.dialog = false;
          });
        }
      });
    },
    doChangeSecret: function(){
      this.fetchSecret({secret:this.secret});
    },
    doChangeType: function(){
      this.fetchData({type:this.typeCheat});
    },
  },
  data : () => ({
    secret:"",
    typeCheat: "",
    cheatData: {},
    Overlay: false,
    dialog: false,
    search: '',
    errorSubmit: "",
    invalid: true,
    headers: [
            { text: 'Lib', align: 'center', sortable: false,value: 'Lib',width:"10%" },
            { text: 'Address', align: 'center', sortable: true,value: 'Address',width:"20%"},
            { text: 'Bytes', align: 'center', sortable: true,value: 'Bytes',width:"40%"},
            { text: 'Type', align: 'center', sortable: true,value: 'Type',width:"20%"},
            { text: 'Manage', align: 'center', sortable: false,value: 'Id',width: "10%" },
        ],
    items: [],
  }),
  watch:{
    // secret: function(newSecret){
    //   this.fetchSecret({secret:newSecret});
    // }
  },
  mounted(){
    this.fetchData();
    this.fetchSecret();
  }
}
</script>
<style>
</style>